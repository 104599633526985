import { usePayrollOrder } from '/~/composables/batch-order/payroll'
import { BatchOrderUnsupportedPayees } from '../core/BatchOrderUnsupportedPayees'

export function usePayrollOrderUnsupportedPayees() {
  const { payrollOrder } = usePayrollOrder()

  const unsupportedPayees = new BatchOrderUnsupportedPayees({
    url: `/v3/batch-orders/${payrollOrder.number}/unsupported-payees`,
    perPage: '10',
  })

  return {
    unsupportedPayees,
  }
}
