<script setup lang="ts">
import { Payee } from '/~/types/api'
import CellLoading from '/~/extensions/bank-file-upload/components/common/cell-loading.vue'
import { useLocalization } from '/~/composables/localization'
import PayToRowStatus from './pay-to-row-status.vue'

export type Row = {
  accountName: string
  bsb: string
  accountNumber: string
  payee: Payee
  status: 'excluded'
  subStatus:
    | 'bsb_invalid'
    | 'account_invalid'
    | 'remitter_name_invalid'
    | 'payee_blacklisted'
}

defineProps<{
  row: Row
  loading: boolean
}>()

const { formatBsb } = useLocalization()
</script>

<template>
  <div class="flex-col">
    <div class="flex min-h-14 w-full items-center text-sm">
      <div class="w-1/2 px-5">
        <cell-loading :loading="loading">
          {{ row.accountName }}
        </cell-loading>
      </div>
      <div class="space-x-0.5 px-5">
        <cell-loading :loading="loading">
          {{ formatBsb(row.bsb) }},
        </cell-loading>
        <cell-loading :loading="loading">
          {{ row.accountNumber }}
        </cell-loading>
      </div>
    </div>
    <pay-to-row-status
      :sub-status="row.subStatus"
      class="-mb-px flex w-full items-center space-x-1 border-x-0 px-5 py-0.5 text-left"
    ></pay-to-row-status>
  </div>
</template>
